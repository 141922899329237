<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
              <!-- <template>
                    {{ $t('globalTrans.selectList')}}<span class="text-danger">*</span><br><br>
                </template> -->
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <b-row>
                <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Reason" vid="reason" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="reason"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('germplasm.reason')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-textarea
                      id="reason"
                      v-model="rejectForm.reason"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Reason (Bn)" vid="reason_bn" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="reason_bn"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('germplasm.reason_bn')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-textarea
                      id="reason_bn"
                      v-model="rejectForm.reason_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { germplasmRequestReject } from '../../api/routes'

export default {
  props: {
       id: {
            default: null,
            type: [String, Number]
        }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.yes'),
    rejectForm: {
      reason: '',
      reason_bn: ''
    }
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    }
  },
  methods: {
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      let result = null
            result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${germplasmRequestReject}/${this.id}`, this.rejectForm)
            if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: 'Data updated successfully',
                color: '#D6E09B'
                })
                this.$bvModal.hide('modal-3')
            } else {
                if (result.error) {
                    this.$refs.form.setErrors(result.errors)
                    this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                    })
                } else {
                    this.$toast.error({
                    title: 'Error',
                    message: result.message
                    })
                }
            }

    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
    }
  }
}
</script>
