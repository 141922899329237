<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Organization" vid='org_id' rules="required|min_value:1">
                      <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.organization')"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="formData.org_id"
                            :options="organizationtList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                        </ValidationProvider>
                      <ValidationProvider name="Request_Type" vid='request_type' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="warehouse_state"
                            slot-scope="{ valid, errors }"
                            >
                            <!-- <template v-slot:label>
                            {{ $t('globalTrans.type')}} <span class="text-danger">*</span>
                            </template> -->
                            <b-form-radio-group
                                v-model="formData.request_type"
                                :options="options"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-radio-group>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    <b-overlay class="mt-4" :show="loading">
                      <b-tabs content-class="mt-3 ml-4">
                        <b-tab :active="select">
                              <b-row>
                                <!-- <b-col lg="6" sm="12">
                                  <ValidationProvider name="Germplasm Organization" vid="germplasm_organization" :rules="rule1">
                                    <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="germplasm_organization_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('germplasm.germplasm_organization') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                        plain
                                        v-model="formData.gn_orgazination_id"
                                        :options="gpOrgList"
                                        id="germplasm_organization_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col> -->
                               <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                                  <ValidationProvider name="Germplasm Organization Name (En)"  vid="germplasm_organization_name" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="germplasm_organization_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('germplasm.germplasm_organization_name') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          rows="4"
                                          id="germplasm_organization_name"
                                          v-model="formData.gn_organization_name"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                               </b-col>
                               <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                                  <ValidationProvider name="Germplasm Organization Name (Bn)"  vid="germplasm_organization_name_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="germplasm_organization_name_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('germplasm.germplasm_organization_name') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          rows="4"
                                          id="germplasm_organization_name_bn"
                                          v-model="formData.gn_organization_name_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                               </b-col>
                               <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                                <ValidationProvider name="Contact Number" vid="contact_no" :rules="`required`">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="contact_no"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('germplasm.contact_number')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      id="contact_no"
                                      v-model="formData.contact_no"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                                <ValidationProvider name="Email" vid="email" :rules="`required|email`">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="email"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      id="email"
                                      v-model="formData.email"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                                <ValidationProvider name="Address" vid="address" :rules="`required`">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="address"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{$t('globalTrans.address_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                      id="address"
                                      v-model="formData.address"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                                  <ValidationProvider name="Address Bn" vid="address_bn" :rules="`required`">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="address_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('globalTrans.address_bn')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          id="address"
                                          v-model="formData.address_bn"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="formData.request_type === 1">
                                  <ValidationProvider name="TIN No." vid="tin_no" :rules="`required`">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="tin_no"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('fertilizerConfig.tin_no')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          id="tin_no"
                                          v-model="formData.tin_no"
                                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                </b-col>
                              </b-row>
                        </b-tab>
                        <b-tab :active="select2">
                          <b-row>
                            <!-- <b-col lg="6" sm="12">
                              <ValidationProvider name="Receiver Code" vid="receiver_code" :rules="rule2">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="receiver_code"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('germplasm.receiver_code')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="receiver_code"
                                    v-model="formData.gn_receiver_info_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                              <ValidationProvider name="Receiver Name (En)"  vid="receiver_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="receiver_name"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('germplasm.receiver_name') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      rows="4"
                                      id="receiver_name"
                                      v-model="formData.receiver_name"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                              <ValidationProvider name="Receiver Name (Bn)"  vid="receiver_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="receiver_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('germplasm.receiver_name') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      rows="4"
                                      id="receiver_name_bn"
                                      v-model="formData.receiver_name_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                              <ValidationProvider name="NID" vid="nid" :rules="`required`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="nid"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('germplasm.nid')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="nid"
                                      v-model="formData.nid"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                              <ValidationProvider name="TIN No." vid="tin_no" :rules="`required`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="tin_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('fertilizerConfig.tin_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="tin_no"
                                      v-model="formData.tin_no"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                              <ValidationProvider name="Trade License" vid="trade_license" :rules="`required`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="trade_license"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('germplasm.trade_license')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="trade_license"
                                      v-model="formData.trade_license"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                            <ValidationProvider name="Contact Number" vid="contact_number" :rules="`required`">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="contact_number"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('germplasm.contact_number')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="contact_number"
                                  v-model="formData.contact_no"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                            <ValidationProvider name="Email" vid="email" :rules="`required|email`">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="email"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="email"
                                  v-model="formData.email"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                            <ValidationProvider name="Address" vid="address" :rules="`required`">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="address"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.address_en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="address"
                                  v-model="formData.address"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="formData.request_type === 2">
                              <ValidationProvider name="Address Bn" vid="address_bn" :rules="`required`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.address_bn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="address"
                                      v-model="formData.address_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </b-tab>
                        <b-tab :active="select3">
                        <b-row><b-col lg="6" sm="12">
                          <ValidationProvider name="Farmer Name (En)" vid="farmer_name" :rules="rule3">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="farmer_name_en"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.farmer_name_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="farmer_name_en"
                                v-model="formData.farmer_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                          <ValidationProvider name="Farmer Name (Bn)" vid="farmer_name_bn" :rules="rule3">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="farmer_name_bn"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.farmer_name_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="farmer_name_en"
                                v-model="formData.farmer_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                          <ValidationProvider name="Contact Number" vid="contact_number" :rules="rule3">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="contact_number"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.contact_number')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="contact_number"
                                v-model="formData.contact_no"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                          <ValidationProvider name="Email" vid="email" :rules="rule3">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="email"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="email"
                                v-model="formData.email"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                          <ValidationProvider name="Address" vid="address" :rules="rule3">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="address"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.address_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="address"
                                v-model="formData.address"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Address Bn" vid="address_bn" :rules="rule3">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="address_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.address_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="address"
                                    v-model="formData.address_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-tab>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Type"  vid="crop_type_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germConfig.cropType')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.crop_type_id"
                                  :options="cropTypeList"
                                  id="crop_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Name"  vid="crop_name_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_name_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germConfig.cropName')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData.crop_name_id"
                                  :options="cropNameList"
                                  id="crop_name_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Variety"  vid="variety_id" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="variety_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('germplasm.variety')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.variety_id"
                                    :options="varietyList"
                                    id="variety_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Season"  vid="season_id" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="season_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('germplasm.season')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.season_id"
                                    :options="seasonList"
                                    id="season_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Number of germplasm" vid="number_of_germplasm" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="number_of_germplasm"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.number_of_germplasm_needed')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="number_of_germplasm"
                                  v-model="formData.number_of_germplasm"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Specific character of the variety (En)"  vid="research_place" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="specific_character_of_variety"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('germplasm.specific_character_of_the_variety_en') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  rows="4"
                                  id="specific_character_of_variety"
                                  v-model="formData.specific_character_of_variety"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Specific character of the variety (Bn)"  vid="research_place_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="specific_character_of_variety_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.specific_character_of_the_variety_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  rows="4"
                                  id="specific_character_of_variety_bn"
                                  v-model="formData.specific_character_of_variety_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Unit" vid="unit" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="unit"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('germplasm.unit') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.unit_id"
                              :options="measurementUnitList"
                              id="unit"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Quantity" vid="quantity" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="quantity"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('procurement.quantity')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="mobile_no"
                              v-model="formData.quantity"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>

                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Request Reason (En)"  vid="request_reason" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="request_reason"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.request_reason_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-textarea
                                  rows="4"
                                  id="request_reason"
                                  v-model="formData.request_reason"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Request Reason (Bn)"  vid="request_reason_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="request_reason_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.request_reason_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-textarea
                                  rows="4"
                                  id="request_reason"
                                  v-model="formData.request_reason_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Request Place"  vid="research_place" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="research_place"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.request_place_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  rows="4"
                                  id="request_reason"
                                  v-model="formData.research_place"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Request Place (Bn)"  vid="research_place_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="research_place_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.request_place_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  rows="4"
                                  id="request_reason"
                                  v-model="formData.research_place_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('procurement.remarks')}}
                                </template>
                                <b-form-textarea
                                    rows="4"
                                    id="remarks"
                                    v-model="formData.remarks"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('procurement.remarks_bn')}}
                                </template>
                                <b-form-textarea
                                    rows="4"
                                    id="remarks"
                                    v-model="formData.remarks_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                      </b-row>
                      </b-tabs>
                    </b-overlay>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
import { germplasmRequestStore, germplasmRequestUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        org_id: 0,
        request_type: 1,
        admin_status: 2,
        gn_orgazination_id: '0',
        gn_organization_name: '',
        gn_organization_name_bn: '',
        gn_receiver_info_id: '0',
        receiver_name: '',
        receiver_name_bn: '',
        request_date: '',
        accession_number: '',
        unit_id: '',
        quantity: '',
        remarks: '',
        remarks_bn: '',
        farmer_name: '',
        farmer_name_bn: '',
        contact_no: '',
        email: '',
        reason: '',
        reason_bn: '',
        distribute_quantity: '',
        dis_remarks: '',
        dis_remarks_bn: '',
        address: '',
        address_bn: '',
        contact_number: '',
        trade_license: '',
        crop_type: '',
        crop_name: '',
        crop_type_id: 0,
        crop_name_id: 0,
        characterization_details: '',
        characterization_type: '',
        local_name: '',
        scientific_name: '',
        english_name: '',
        conservation_type: '',
        production_date: '',
        current_date: '',
        sale_center_id: '',
        fertilizer_type_id: '',
        dealer_name: '',
        fertilizer_name_id: '',
        dealer_com_farmer_id: '',
        bill_no: '',
        tin_no: '',
        n_id: '',
        phone_no: '',
        status: '1',
        request_reason: '',
        request_reason_bn: '',
        research_place: '',
        research_place_bn: '',
        variety_id: '0',
        season_id: '0',
        number_of_germplasm: '',
        specific_character_of_variety: '',
        specific_character_of_variety_bn: ''
      },
      loading: false,
      tab: 'active',
      tab2: '',
      rule1: '',
      rule2: '',
      rule3: '',
      select: true,
      dateerror: null,
      select2: false,
      select3: false,
      cropNameList: [],
      varietyList: [],
      districtList: [],
      upazilaList: [],
      seasonList: [],
      options: [
          { item: 1, name: this.$t('germplasm.germplasm_organization') },
          { item: 2, name: this.$t('germplasm.germplasm_receiver') },
          { item: 3, name: this.$t('germplasm.farmer') }
      ]
    }
  },
  computed: {
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    organizationtList: function () {
      const orgComponentList = this.$store.state.orgComponentList.filter(item => item.component_id === 8)
      const orgIdArr = []
      if (orgComponentList.length) {
        orgComponentList.filter(item => {
          orgIdArr.push(item.org_id)
        })
      }
      return this.$store.state.commonObj.organizationProfileList.filter(item => {
        if (orgIdArr.includes(item.value)) {
          return item
        }
      })
      // return this.$store.state.commonObj.organizationProfileList
    },
    gpOrgList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.gpOrganizationsList.filter(item => item.status === 1)
    },
    measurementUnitList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.measurementUnitList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.request_type': function (newVal, oldVal) {
      if (this.id) {
        if (newVal === 1) {
            this.select = true
            this.rule1 = 'required|min_value:1'
            this.select2 = false
            this.rule2 = ''
            this.select3 = false
            this.rule3 = ''
        } else if (newVal === 2) {
            this.select = false
            this.rule1 = ''
            this.select2 = true
            this.rule2 = 'required'
            this.select3 = false
            this.rule3 = ''
        } else {
            this.select = false
            this.rule1 = ''
            this.select2 = false
            this.rule2 = ''
            this.select3 = true
            this.rule3 = 'required'
        }
      } else {
        if (newVal === 1) {
          this.formData.receiver_name = ''
          this.formData.receiver_name_bn = ''
          this.formData.nid = ''
          this.formData.trade_license = ''
          this.formData.gn_orgazination_id = ''
          this.formData.gn_receiver_info_id = ''
          this.formData.accession_number = ''
          this.formData.request_date = ''
            this.select = true
            this.rule1 = 'required|min_value:1'
            this.select2 = false
            this.rule2 = ''
            this.select3 = false
            this.rule3 = ''
        } else if (newVal === 2) {
            this.select = false
            this.rule1 = ''
            this.select2 = true
            this.rule2 = 'required'
            this.select3 = false
            this.rule3 = ''
        } else {
          // this.formData.contact_no = ''
          this.formData.receiver_name = ''
          this.formData.nid = ''
          // this.formData.address_bn = ''
          // this.formData.address = ''
          this.formData.tin = ''
          // this.formData.email = ''
          this.formData.trade_license = ''
          // this.formData.gn_orgazination_id = ''
          // this.formData.gn_receiver_info_id = ''

            this.select = false
            this.rule1 = ''
            this.select2 = false
            this.rule2 = ''
            this.select3 = true
            this.rule3 = 'required'
        }
      }
    },
    'formData.org_id': function (newVal, oldVal) {
      if (newVal === 6) {
        this.seasonList = this.$store.state.SeedsFertilizer.commonObj.seasonList
      } else {
        this.seasonList = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
      }
    },
    'formData.gn_orgazination_id': function (newVal, oldVal) {
      if (newVal) {
        // this.districtList = this.getOrgData(newVal)
      }
    },
    'formData.gn_receiver_info_id': function (newVal, oldVal) {
      if (newVal) {
        // this.districtList = this.getReceiverData(newVal)
      }
    },
    'formData.accession_number': function (newVal, oldVal) {
      if (newVal) {
        // this.districtList = this.getAccessionData(newVal)
      } else {
        this.formData.crop_type = ''
        this.formData.crop_name = ''
        this.formData.local_name = ''
        this.formData.characterization_type = ''
        this.formData.characterization_details = ''
        this.formData.conservation_type = ''
      }
    },
    'formData.crop_type_id': function (newVal, oldVal) {
      this.cropNameList = this.getCropNameList(newVal)
    },
    'formData.crop_name_id': function (newVal, oldVal) {
      const cropNameObj = this.cropNameList.find(item => item.value === newVal)
      if (typeof cropNameObj !== 'undefined') {
        if (this.currentLocale === 'bn') {
          this.formData.scientific_name = cropNameObj.scientific_name_bn
          this.formData.english_name = cropNameObj.english_name_bn
        } else {
          this.formData.scientific_name = cropNameObj.scientific_name
          this.formData.english_name = cropNameObj.english_name
        }
      } else {
        this.formData.scientific_name = ''
        this.formData.english_name = ''
      }
      this.varietyList = this.getVarietyList(newVal)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      if (this.formData.request_type === 1) {
        this.formData.farmer_name = ''
        this.formData.farmer_name_bn = ''
        this.formData.gn_receiver_info_id = ''
        this.formData.accession_number = ''
        this.formData.request_date = ''
      } else if (this.formData.request_type === 2) {
        this.formData.farmer_name = ''
        this.formData.farmer_name_bn = ''
        this.formData.gn_orgazination_id = ''
        // this.formData.crop_type_id = ''
        // this.formData.crop_name_id = ''
        // this.formData.request_reason = ''
        // this.formData.request_reason_bn = ''
        // this.formData.research_place = ''
        // this.formData.research_place_bn = ''
      } else {
        this.formData.gn_receiver_info_id = ''
        this.formData.gn_orgazination_id = ''
        // this.formData.crop_type_id = ''
        // this.formData.crop_name_id = ''
        // this.formData.request_reason = ''
        // this.formData.request_reason_bn = ''
        // this.formData.research_place = ''
        // this.formData.research_place_bn = ''
      }

      this.$store.dispatch('mutateCommonProperties', { loading: true })
      this.loading = true
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${germplasmRequestUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, germplasmRequestStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    // async getOrgData (orgId = null) {
    //   this.loading = true
    //   await RestApi.getData(seedFertilizerServiceBaseUrl, getDataByOrgId + orgId).then(response => {
    //     if (response.success) {
    //       this.formData.contact_no = response.data.phone_no
    //       this.formData.email = response.data.email
    //       this.formData.address = this.$i18n.locale === 'en' ? response.data.address : response.data.address_bn
    //       this.formData.address_bn = response.data.address_bn
    //       this.formData.tin = response.data.tin_no
    //     } else {
    //       this.formData.contact_no = ''
    //       this.formData.email = ''
    //       this.formData.address = ''
    //       this.formData.address_bn = ''
    //       this.formData.tin = ''
    //     }
    //   })
    //   this.loading = false
    // },
    // async getReceiverData (recCode = null) {
    //   this.loading = true
    //   await RestApi.getData(seedFertilizerServiceBaseUrl, getDataByRecieverCode + recCode).then(response => {
    //     if (response.success) {
    //       this.formData.contact_no = response.data.phone_no
    //       this.formData.receiver_name = response.data.receiver_name
    //       this.formData.nid = response.data.nid
    //       this.formData.address_bn = response.data.address_bn
    //       this.formData.address = this.$i18n.locale === 'en' ? response.data.address : response.data.address_bn
    //       this.formData.trade_license = response.data.trade_license_no
    //       this.formData.tin = response.data.tin_no
    //       this.formData.email = response.data.email
    //     } else {
    //       this.formData.contact_no = ''
    //       this.formData.receiver_name = ''
    //       this.formData.nid = ''
    //       this.formData.address_bn = ''
    //       this.formData.address = ''
    //       this.formData.trade_license = ''
    //       this.formData.tin = ''
    //       this.formData.email = ''
    //     }
    //   })
    //   this.loading = false
    // },
    // async getAccessionData () {
    //   this.loading = true
    //   await RestApi.getData(seedFertilizerServiceBaseUrl, getAccessionInfo, this.formData).then(response => {
    //     if (response.success) {
    //       this.formData.crop_type_id = response.data.crop_type_id
    //       this.formData.crop_type = this.$i18n.locale === 'en' ? response.data.crop_type : response.data.crop_type_bn
    //       this.formData.crop_name_id = response.data.crop_name_id
    //       this.formData.crop_name = this.$i18n.locale === 'en' ? response.data.crop_name : response.data.crop_name_bn
    //       // this.formData.scientific_name = this.$i18n.locale === 'en' ? response.data.scientific_name : response.data.scientific_name_bn
    //       // this.formData.english_name = this.$i18n.locale === 'en' ? response.data.english_name : response.data.english_name_bn
    //       this.formData.local_name = this.$i18n.locale === 'en' ? response.data.local_name : response.data.local_name_bn
    //       this.formData.characterization_type = this.$i18n.locale === 'en' ? response.data.characterization_type : response.data.characterization_type_bn
    //       this.formData.characterization_details = this.$i18n.locale === 'en' ? response.data.characterization_details : response.data.characterization_details_bn
    //       this.formData.conservation_type = this.$i18n.locale === 'en' ? response.data.conservation_type : response.data.conservation_type_bn
    //     } else {
    //       this.formData.crop_type = ''
    //       this.formData.crop_name = ''
    //       // this.formData.scientific_name = ''
    //       // this.formData.english_name = ''
    //       this.formData.local_name = ''
    //       this.formData.characterization_type = ''
    //       this.formData.characterization_details = ''
    //       this.formData.conservation_type = ''
    //     }
    //   })
    //   this.loading = false
    // },
    getCropNameList (cropTypeId = null) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (cropTypeId) {
        return cropNameList.filter(item => item.crop_type_id === cropTypeId)
      }
      return cropNameList
    },
    getVarietyList (cropNameId = null) {
      const varietyList = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.status === 1)
      if (cropNameId) {
        return varietyList.filter(item => item.crop_name_id === cropNameId)
      }
      return varietyList
    }
  }
}
</script>
