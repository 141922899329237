<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card class="text-black">
          <template v-slot:body>
            <b-row>
              <b-col>
                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="germplasm/config/report-heading/detail" :org-id="formData.org_id">
                  {{ $t('germplasm.germplasm_requset') + ' ' + $t('germplasm.form') + ' ' + formData.crop_name + ' ' + $t('germplasm.germplasm') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <p>{{ $t('germplasm.germplasm_purposes') }}</p>
                <p class="font-italic mb-4">
                  <span><strong>{{ $t('germplasm.name_of_varieties') }}</strong> : {{ formData.variety_name }}</span><br>
                  <span><strong>{{ $t('germplasm.season') }}</strong> : {{ formData.season_name }}</span><br>
                  <span><strong>{{ $t('germplasm.number_of_germplasm_needed') }}</strong>: {{ $n(formData.number_of_germplasm) }}</span><br>
                  <span><strong>{{ $t('germplasm.specific_character_of_the_variety') }}</strong> : {{ currentLocale === 'en' ? formData.specific_character_of_variety : formData.specific_character_of_variety_bn }}</span><br>
                </p>
                <p>
                  <span>{{ $t('germplasm.request_form_introduction') }}</span><br>
                  <span>{{ currentLocale === 'en' ? formData.request_reason : formData.request_reason_bn }}</span>
                </p>
                <p class="font-italic mb-3">{{ $t('germplasm.title_of_research') }}</p>
                <p class="font-italic"><strong>{{ $t('germplasm.place_where_research_conducted') }}</strong> : {{ currentLocale === 'en' ? formData.research_place : formData.research_place_bn }}</p>
                <p>{{ $t('germplasm.request_form_description') }}</p>
                <div class="mt-1 mb-5">
                  <span><strong>{{ $t('globalTrans.name') }}</strong>: {{ formData.applicant_name }}</span><br>
                  <span><strong>{{ $t('germplasm.request_form_designation') }}</strong>: </span><br>
                  <span><strong>{{ $t('germplasm.request_form_department') }}</strong>: </span><br>
                  <span><strong>{{ $t('globalTrans.address') }}</strong>: {{ currentLocale === 'en' ? formData.address : formData.address_bn }}</span><br>
                  <div class="row">
                    <div class="col-md-6"><strong>{{ $t('germplasm.request_form_cell') }}</strong>: {{ formData.contact_no }}</div>
                    <div class="col-md-6"><strong>{{ $t('globalTrans.email') }}</strong>:{{ formData.email }}</div>
                  </div>
                </div>
                <p>
                  <span>{{ $t('globalTrans.signature') }}: _______________________________</span><br>
                  <span>{{ $t('globalTrans.date') }}: _______________________________</span><br>
                </p>
                <p class="font-italic font-weight-bold mt-4">{{ $t('germplasm.request_form_submit_office') }}</p>
                <p class="text-muted"></p>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { getDataByRecieverCode, getDataByOrgId, getAccessionInfo } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  async created () {
    if (this.id) {
      const tmp = this.getFormData()
      const temps = Object.assign(this.formData, tmp)
      this.formData = temps
      this.formData = this.getCustomDataList(temps)
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      formData: {
        id: '',
        org_id: 0,
        request_type: 1,
        gn_orgazination_id: '',
        gn_receiver_info_id: '',
        request_date: '',
        accession_number: '',
        unit_id: '',
        quantity: '',
        remarks: '',
        remarks_bn: '',
        farmer_name: '',
        farmer_name_bn: '',
        contact_no: '',
        email: '',
        reason: '',
        reason_bn: '',
        distribute_quantity: '',
        dis_remarks: '',
        dis_remarks_bn: '',
        organization_name: '',
        gp_organization_name: '',
        address: '',
        address_bn: '',
        contact_number: '',
        trade_license: '',
        crop_type: '',
        crop_name: '',
        characterization_details: '',
        characterization_type: '',
        local_name: '',
        scientific_name: '',
        english_name: '',
        conservation_type: '',
        production_date: '',
        current_date: '',
        sale_center_id: '',
        fertilizer_type_id: '',
        dealer_name: '',
        fertilizer_name_id: '',
        dealer_com_farmer_id: '',
        bill_no: '',
        tin_no: '',
        n_id: '',
        phone_no: '',
        status: '1',
        variety_name: '',
        season_name: '',
        applicant_name: ''
      },
      tab: 'active',
      tab2: '',
      rule1: '',
      rule2: '',
      rule3: '',
      select: true,
      dateerror: null,
      select2: false,
      select3: false,
      districtList: [],
      upazilaList: [],
      options: [
          { item: 1, name: this.$t('germplasm.germplasm_organization') },
          { item: 2, name: this.$t('germplasm.germplasm_receiver') },
          { item: 3, name: this.$t('germplasm.farmer') }
        ],
      details: [],
      list: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl
    }
  },
  computed: {
    currentLocale: function () {
      return this.$i18n.locale
    },
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    cropNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
    },
    gpOrganizationsList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.gpOrganizationsList.filter(item => item.status === 1)
    },
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    varietyList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.VarietyList
    },
    seasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.gn_orgazination_id': function (newVal, oldVal) {
      if (this.formData.request_type === 1) {
        this.getOrgData(newVal)
      }
    },
    'formData.gn_receiver_info_id': function (newVal, oldVal) {
      if (this.formData.request_type === 2) {
        this.getReceiverData(newVal)
      }
    }
  },
  methods: {
     getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFormattedDate (date) {
      const dateArr = date.split('-')
      return this.$n(parseInt(dateArr[0]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[1]), { useGrouping: false }) + '-' + this.$n(parseInt(dateArr[2]), { useGrouping: false })
    },
    pdfExport () {
    const reportTitle = this.$t('germplasm.germplasm_requset')
    ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this)
    },
    getCustomDataList (data) {
        const collectionObj = this.$store.state.SeedsFertilizer.commonObj.gpUnitList.find(item => item.value === parseInt(data.unit_id))
        const collectionData = {}
        if (typeof collectionObj !== 'undefined') {
          collectionData.unit = collectionObj.text_en
          collectionData.unit_bn = collectionObj.text_bn
        } else {
          collectionData.unit = ''
          collectionData.unit_bn = ''
        }
        const organizationObj = this.organizationtList.find(item => item.value === this.formData.org_id)
        if (typeof organizationObj !== 'undefined') {
          this.formData.organization_name = this.currentLocale === 'en' ? organizationObj.text_en : organizationObj.text_bn
        } else {
          this.formData.organization_name = ''
        }
        const cropNameObj = this.cropNameList.find(item => item.value === this.formData.crop_name_id)
        if (typeof cropNameObj !== 'undefined') {
          this.formData.crop_name = this.currentLocale === 'en' ? cropNameObj.text_en : cropNameObj.text_bn
        } else {
          this.formData.crop_name = ''
        }
        const variety = this.varietyList.find(item => item.value === this.formData.variety_id)
        if (typeof variety !== 'undefined') {
          this.formData.variety_name = this.currentLocale === 'en' ? variety.text_en : variety.text_bn
        } else {
          this.formData.variety_name = ''
        }
        var seasonList = ''

        if (data.org_id === 6) {
          seasonList = this.$store.state.SeedsFertilizer.commonObj.seasonList
        } else {
          seasonList = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList
        }

        const season = seasonList.find(item => item.value === this.formData.season_id)

        if (typeof season !== 'undefined') {
          this.formData.season_name = this.currentLocale === 'en' ? season.text_en : season.text_bn
        } else {
          this.formData.season_name = ''
        }
        if (this.formData.request_type === 3) {
          this.formData.applicant_name = this.currentLocale === 'en' ? this.formData.farmer_name : this.formData.farmer_name_bn
        }
        const formdata = Object.assign({}, this.formData, collectionData)
        return formdata
    },
    getOrgData (orgId = null) {
      RestApi.getData(seedFertilizerServiceBaseUrl, getDataByOrgId + orgId).then(response => {
        if (response.success) {
          this.formData.applicant_name = this.currentLocale === 'en' ? response.data.organization_name : response.data.organization_name_bn
          this.formData.contact_no = response.data.phone_no
          this.formData.email = response.data.email
          this.formData.address = response.data.address
          this.formData.address_bn = response.data.address_bn
          this.formData.tin = response.data.tin_no
        } else {
          this.formData.applicant_name = ''
          this.formData.contact_no = ''
          this.formData.email = ''
          this.formData.address = ''
          this.formData.address_bn = ''
          this.formData.tin = ''
        }
      })
    },
    getReceiverData (recCode = null) {
      RestApi.getData(seedFertilizerServiceBaseUrl, getDataByRecieverCode + recCode).then(response => {
        if (response.success) {
          this.formData.applicant_name = this.currentLocale === 'en' ? response.data.receiver_name : response.data.receiver_name_bn
          this.formData.contact_no = response.data.phone_no
          this.formData.receiver_name = response.data.receiver_name
          this.formData.nid = response.data.nid
          this.formData.address_bn = response.data.address_bn
          this.formData.address = response.data.address
          this.formData.trade_license = response.data.trade_license_no
          this.formData.tin = response.data.tin_no
          this.formData.email = response.data.email
        } else {
          this.formData.applicant_name = ''
          this.formData.contact_no = ''
          this.formData.receiver_name = ''
          this.formData.nid = ''
          this.formData.address_bn = ''
          this.formData.address = ''
          this.formData.trade_license = ''
          this.formData.tin = ''
          this.formData.email = ''
        }
      })
    },
    getAccessionData () {
      RestApi.getData(seedFertilizerServiceBaseUrl, getAccessionInfo, this.formData).then(response => {
        if (response.success) {
          this.formData.crop_type = response.data.crop_type
          this.formData.crop_name = response.data.crop_name
          this.formData.scientific_name = response.data.scientific_name
          this.formData.english_name = response.data.english_name
          this.formData.local_name = response.data.local_name
          this.formData.characterization_type = response.data.characterization_type
          this.formData.characterization_details = response.data.characterization_details
          this.formData.conservation_type = response.data.conservation_type
        } else {
          this.formData.crop_type = ''
          this.formData.crop_name = ''
          this.formData.scientific_name = ''
          this.formData.english_name = ''
          this.formData.local_name = ''
          this.formData.characterization_type = ''
          this.formData.characterization_details = ''
          this.formData.conservation_type = ''
        }
      })
    }
  }
}
</script>
