<template>
    <b-container fluid>
        <iq-card>
             <template v-slot:body>
                <b-row>
                    <b-col class="col-lg-4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="crop_type_id"
                      >
                        <template v-slot:label>
                          {{$t('germConfig.cropType')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.crop_type_id"
                            :options="cropTypeList"
                            id="crop_type_id"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col class="col-lg-4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="crop_name_id"
                      >
                        <template v-slot:label>
                          {{$t('germConfig.cropName')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.crop_name_id"
                            :options="cropNameList"
                            id="crop_name_id"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col class="col-sm-2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('germplasm.germplasm_requset')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                         <template v-slot:cell(quantity)="data">
                                            {{ $n(data.item.quantity, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(request_type)="data">
                                            <div  v-if="data.item.request_type === 1">
                                            {{ $t('germplasm.germplasm_organization') }}
                                            </div>
                                            <div  v-else-if="data.item.request_type === 2">
                                            {{ $t('germplasm.germplasm_receiver') }}
                                            </div>
                                            <div v-else>
                                            {{ $t('germplasm.farmer') }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(name)="data">
                                          <span v-if="data.item.request_type ===1">
                                                {{ currentLocale === 'en' ? data.item.gn_organization_name : data.item.gn_organization_name_bn }}
                                            </span>
                                            <span v-else-if="data.item.request_type ===2">
                                                {{ currentLocale === 'en' ? data.item.receiver_name : data.item.receiver_name_bn }}
                                            </span>
                                            <span v-else>
                                                {{ currentLocale === 'en' ? data.item.farmer_name : data.item.farmer_name_bn }}
                                            </span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                        <b-button v-if="data.item.status == 1" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        <b-button v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)"><i class="ri-eye-line"></i></b-button>
                                        <b-button v-b-modal.modal-6 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="view(data.item)"><i class="far fa-envelope"></i></b-button>
                                        <b-button v-if="data.item.status == 1" variant=" iq-bg-success mr-1 mb-1" size="sm" @click="accept(data.item)"><i class="ri-check-fill m-0"></i></b-button>
                                        <b-button v-if="data.item.status == 1" v-b-modal.modal-3 variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-info" v-if="data.item.admin_status == 1 && data.item.status == 1">{{$t('germplasm.online_pending')}}</span>
                                            <span class="badge badge-info" v-if="data.item.status == 1 && data.item.admin_status == 2">{{$t('globalTrans.pending')}}</span>
                                            <span class="badge badge-success" v-if="data.item.status == 2">{{$t('globalTrans.approved')}}</span>
                                            <span class="badge badge-danger" v-if="data.item.status == 3">{{$t('globalTrans.reject')}}</span>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-3" size="lg" :title="formTitleReject" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <p>
                <FormR :id="editItemId" :key="editItemId"/>
            </p>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('germplasm.germplasm_requset') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <template #modal-title class="flex-grow-1">
            {{ $t('germplasm.germplasm_requset') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
        <b-modal id="modal-6" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('germplasm.germplasm_requset') + ' ' + $t('germplasm.form') }}
          </template>
          <RequestForm :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RequestForm from './RequestForm'
import FormR from './Reject'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { germplasmRequestList, germplasmRequestApprove, germplasmRequestReject } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details, FormR, RequestForm
    },
    data () {
        return {
            search: {
                crop_type_id: '0',
                crop_name_id: '0',
                org_id: this.$store.state.dataFilters.orgId
            },
            cropNameList: []
        }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('germplasm.germplasm_requset') + ' ' + this.$t('globalTrans.entry') : this.$t('germplasm.germplasm_requset') + ' ' + this.$t('globalTrans.update')
      },
      formTitleReject () {
          return this.$t('globalTrans.rejectMsg')
      },
      columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('germplasm.crop_type'), class: 'text-left' },
            { label: this.$t('germplasm.crop'), class: 'text-left' },
            { label: this.$t('germplasm.request_type'), class: 'text-left' },
            { label: this.$t('globalTrans.name'), class: 'text-left' },
            { label: this.$t('germplasm.quantity'), class: 'text-left' },
            { label: this.$t('globalTrans.status'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        /*eslint-disable */
        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'crop_type_bn' },
            { key: 'crop_name_bn' },
            { key: 'request_type' },
            { key: 'name' },
            { key: 'quantity' },
            { key: 'status' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'crop_type' },
            { key: 'crop_name' },
            { key: 'request_type' },
            { key: 'name' },
            { key: 'quantity' },
            { key: 'status' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      },
      cropTypeList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
      }
    },
    created () {
      this.loadData()
    },
    mounted () {
      core.index()
    },
    watch: {
      'search.crop_type_id': function (newVal, oldVal) {
        this.cropNameList = this.getCropNameList(newVal)
      }
    },
    methods: {
        edit (item) {
           this.editItemId = item.id
        },
        view (item) {
           this.editItemId = item.id
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, germplasmRequestReject, item, 'fertilizer', 'germplasmRequestList')
        },
        accept (item) {
          this.changeStatus(seedFertilizerServiceBaseUrl, germplasmRequestApprove, item, 'fertilizer', 'germplasmRequestList')
        },
        dataChange () {
          this.loadData()
        },
        searchData () {
          this.loadData()
        },
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          await RestApi.getData(seedFertilizerServiceBaseUrl, germplasmRequestList, params).then(response => {
              if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
              } else {
                this.$store.dispatch('setList', [])
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(cropName => cropName.value === parseInt(item.crop_name_id))
            const customisedItem = {}
            if(typeof cropNameObj !== 'undefined') {
              const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(cropType => cropType.value === parseInt(cropNameObj.crop_type_id))
              if(typeof cropTypeObj !== 'undefined') {
                customisedItem.crop_type = cropTypeObj.text_en
                customisedItem.crop_type_bn = cropTypeObj.text_bn
              } else {
                customisedItem.crop_type = ''
                customisedItem.crop_type_bn = ''
              }
              customisedItem.crop_name = cropNameObj.text_en
              customisedItem.crop_name_bn = cropNameObj.text_bn
            } else {
              customisedItem.crop_name = ''
              customisedItem.crop_name_bn = ''
              customisedItem.crop_type = ''
              customisedItem.crop_type_bn = ''
            }
            return Object.assign({}, item, customisedItem)
          })
          return listData
        },
        pdfExport () {
          this.$refs.details.pdfExport()
        },
        getCropNameList (cropTypeId = null) {
          const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
          if (cropTypeId) {
            return cropNameList.filter(item => item.crop_type_id === cropTypeId)
          }
          return cropNameList
        }
    }
}
</script>

<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
